import { Document } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import { EmbeddedSigning, Signer } from './DMP/EmbeddedSigning';
import './SignDocument.scss';

const BASE_CLASS = 'adb-sign-document';
const id = 'sign-document-modal';

const SignDocument = ({
  document,
  signers,
  onDMPSignCompleted,
}: {
  document: Document;
  signers: Signer[];
  onDMPSignCompleted: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={BASE_CLASS}>
      <AdbDialog size="maximized" id={id}>
        <AdbDialog.Header>
          <Text variant="hl-100">
            {t('form_fields.documents.signature_title')}
          </Text>
        </AdbDialog.Header>
        <AdbDialog.Content>
          <EmbeddedSigning
            document={document}
            signers={signers}
            onSignCompleted={onDMPSignCompleted}
          />
        </AdbDialog.Content>
      </AdbDialog>
    </div>
  );
};

export default SignDocument;
