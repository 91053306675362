import { useModal } from '@smart/components-adb/molecules/Modal';
import { TableRow } from '@smart/components-adb/molecules/Table/TableComponents/TableComponents';
import { Button, Text } from '@smart/react-components';
import { useLanguageContext } from 'contexts/language-context';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeOwnerModal } from '../ChangeOwnerModal';
import { translateTextIfExists } from '../Table/tasktable.config';
import { useTaskContext } from '../task-context';
import { TaskActionProps } from '../types';
import { getTaskAssignee, getTaskDetails, isCompleted } from '../utils';
import { LeadActionsModal } from './LeadActionsModal';

const BASE_CLASS = 'adb-tasks';

export const LeadTaskDetails = ({
  task: taskRow,
  disabled,
}: Omit<TaskActionProps, 'onComplete'>) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { getTask, refetchTasks } = useTaskContext();

  const { registerModal, closeModal } = useModal();

  const task = taskRow.original;
  const { customer, subtype } = task;

  const taskDetails = getTaskDetails(task, t, locale, customer);
  const customerName = `${customer.firstName} ${customer.lastName}`;

  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const onActionCompleted = (refetchTaskAction = 'single') => {
    setIsLoadingAction(true);
    closeModal();

    if (refetchTaskAction === 'single')
      getTask(task.id).finally(() => setIsLoadingAction(false));
    if (refetchTaskAction === 'all') refetchTasks();
  };

  const taskSubject = t('task.lead.title', {
    customerName,
    separator: subtype ? ':' : '',
    type: subtype
      ? ` ${translateTextIfExists(t, `task.subtypes.${subtype.toLowerCase()}`, subtype)}`
      : '',
  });

  return (
    <TableRow gridColumns="">
      <td className={`${BASE_CLASS}__task-inner`}>
        <div className={`${BASE_CLASS}__task-details`}>
          <Text variant="lbl-200" as="p">{`${taskSubject}`}</Text>
          <Text variant="p-100" as="p">
            {task.description ?? t('task.lead.instruction', { customerName })}
          </Text>
          <div>
            <ul className={`${BASE_CLASS}__task-details-list`}>
              {Object.keys(taskDetails).map((key) => (
                <li key={key}>
                  <Text variant="lbl-100">{t(key)}</Text>
                  {typeof taskDetails[key] === 'string' ||
                  typeof taskDetails[key] === 'number' ? (
                    <Text variant="p-100">{taskDetails[key] as string}</Text>
                  ) : (
                    <>{(taskDetails[key] as () => JSX.Element)()}</>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={`${BASE_CLASS}__task-actions`}>
          <Button
            variant="primary"
            loading={isLoadingAction}
            onClick={() => {
              registerModal(
                <ChangeOwnerModal
                  task={taskRow}
                  onComplete={onActionCompleted}
                />
              );
            }}
          >
            {getTaskAssignee({ task, t })}

            {task.isAgentAssigned ? (
              <Button.Icon
                icon="successful-checkmark"
                aria-label="successful-checkmark"
              />
            ) : (
              <Button.Icon icon="arrow-right" aria-label="arrow-right" />
            )}
          </Button>
          <Button
            variant="primary"
            mode={200}
            onClick={() =>
              registerModal(
                <LeadActionsModal
                  task={taskRow}
                  onComplete={onActionCompleted}
                />
              )
            }
            disabled={
              isLoadingAction ||
              disabled ||
              isCompleted(task) ||
              !task.isAgentAssigned
            }
            loading={isLoadingAction}
          >
            {t('task.actions')}
            <Button.Spinner />
          </Button>
        </div>
      </td>
    </TableRow>
  );
};
