import { Document, DocumentSearchInput } from '@smart/adb-shared';
import { DocumentNode } from 'graphql';
import { client } from 'graphql/client';
import { LoadDocumentsQuery } from '../../queries.generated';

type DocumentCacheHelperParams = {
  query: DocumentNode;
  documentId: string;
  document: Partial<Document>;
  input?: DocumentSearchInput;
};

const getCachedDocuments = (
  input: DocumentCacheHelperParams['input'],
  query: DocumentNode
): Document[] => {
  const data = client.readQuery<LoadDocumentsQuery>({
    query,
    variables: { input },
  });

  return data?.documentSearch.documents ?? [];
};

export const updateDocumentInGqlCache = async ({
  query,
  documentId,
  document,
  input,
}: DocumentCacheHelperParams) => {
  if (!documentId || !input)
    throw new Error("Could not fetch updated document, missing 'documentId'");

  const documents = getCachedDocuments(input, query);

  const updateDocuments = documents.map((item) =>
    item.id === documentId ? { ...item, ...document } : item
  );

  client.writeQuery({
    overwrite: true,
    query,
    variables: {
      input,
    },
    data: {
      documentSearch: {
        documents: updateDocuments,
      },
    },
  });
};
