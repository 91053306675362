/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCarQueryVariables = Types.Exact<{
  input: Types.CarInput;
}>;


export type GetCarQuery = { __typename?: 'Query', getCar: { __typename?: 'ExtendedCar', id: string, type: Types.AssetType, vin: string, carId: string, image: string, orderNumber?: string | null, status: Types.CarStatus, isInfleeted: boolean, countryOfRegistration?: string | null, exterior?: string | null, holdingPeriod?: string | null, installDate?: string | null, interior?: string | null, licensePlateNumber?: string | null, line?: string | null, modelName?: string | null, name?: string | null, purchaseDate?: string | null, purchaseType?: Types.PurchaseType | null, registrationDate?: string | null, preRegistrationDate?: string | null, transmission?: string | null, year?: string | null, features?: { __typename?: 'ExtendedCarFeatures', exterior: string, interior?: string | null, steeringWheelPosition?: string | null, transmission?: string | null } | null } };

export type SearchDocumentsQueryVariables = Types.Exact<{
  input: Types.DocumentSearchInput;
}>;


export type SearchDocumentsQuery = { __typename?: 'Query', documentSearch: { __typename?: 'DocumentSearchResult', documents: Array<{ __typename?: 'Document', dateCreated: string, dateModified: string, id: string, name: string, transactionId: string, status?: string | null, category?: string | null, type?: string | null }> } };

export type LoadSignUrlQueryVariables = Types.Exact<{
  input: Types.LoadSignUrlInput;
}>;


export type LoadSignUrlQuery = { __typename?: 'Query', loadSignURL: { __typename?: 'LoadSignURLResult', signURL: string } };

export type AppointmentQueryVariables = Types.Exact<{
  input: Types.AppointmentInput;
}>;


export type AppointmentQuery = { __typename?: 'Query', appointment: { __typename?: 'Appointment', createdOn: string, end: string, id: string, start: string, status?: Types.AppointmentStatus | null, type?: Types.AppointmentType | null, title: string, car?: { __typename?: 'AppointmentCar', carId: string, licensePlate?: string | null, name?: string | null, registrationDate?: string | null, model?: string | null, line?: string | null, exterior?: string | null, interior?: string | null } | null, customer?: { __typename?: 'CustomerBusiness', firstName: string, lastName: string, uuid: string, userId: string, mobileNumber?: string | null, sfCustomerId?: string | null, bpid?: string | null, accountType?: Types.CustomerAccountType | null, sfOrgId?: string | null, companyName?: string | null } | { __typename?: 'CustomerPrivate', firstName: string, lastName: string, uuid: string, userId: string, mobileNumber?: string | null, sfCustomerId?: string | null, accountType?: Types.CustomerAccountType | null } | null, expert?: { __typename?: 'AppointmentExpert', address: string, fullName: string, id: string } | null } };


export const GetCarDocument = gql`
    query GetCar($input: CarInput!) {
  getCar(input: $input) {
    id
    type
    vin
    carId
    image
    orderNumber
    status
    isInfleeted
    countryOfRegistration
    exterior
    holdingPeriod
    installDate
    interior
    licensePlateNumber
    line
    modelName
    name
    purchaseDate
    purchaseType
    registrationDate
    preRegistrationDate
    transmission
    year
    features {
      exterior
      interior
      steeringWheelPosition
      transmission
    }
  }
}
    `;

/**
 * __useGetCarQuery__
 *
 * To run a query within a React component, call `useGetCarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCarQuery(baseOptions: Apollo.QueryHookOptions<GetCarQuery, GetCarQueryVariables> & ({ variables: GetCarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarQuery, GetCarQueryVariables>(GetCarDocument, options);
      }
export function useGetCarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarQuery, GetCarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarQuery, GetCarQueryVariables>(GetCarDocument, options);
        }
export function useGetCarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCarQuery, GetCarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCarQuery, GetCarQueryVariables>(GetCarDocument, options);
        }
export type GetCarQueryHookResult = ReturnType<typeof useGetCarQuery>;
export type GetCarLazyQueryHookResult = ReturnType<typeof useGetCarLazyQuery>;
export type GetCarSuspenseQueryHookResult = ReturnType<typeof useGetCarSuspenseQuery>;
export type GetCarQueryResult = Apollo.QueryResult<GetCarQuery, GetCarQueryVariables>;
export const SearchDocumentsDocument = gql`
    query SearchDocuments($input: DocumentSearchInput!) {
  documentSearch(input: $input) {
    documents {
      dateCreated
      dateModified
      id
      name
      transactionId
      status
      category
      type
    }
  }
}
    `;

/**
 * __useSearchDocumentsQuery__
 *
 * To run a query within a React component, call `useSearchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchDocumentsQuery(baseOptions: Apollo.QueryHookOptions<SearchDocumentsQuery, SearchDocumentsQueryVariables> & ({ variables: SearchDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(SearchDocumentsDocument, options);
      }
export function useSearchDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDocumentsQuery, SearchDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(SearchDocumentsDocument, options);
        }
export function useSearchDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchDocumentsQuery, SearchDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(SearchDocumentsDocument, options);
        }
export type SearchDocumentsQueryHookResult = ReturnType<typeof useSearchDocumentsQuery>;
export type SearchDocumentsLazyQueryHookResult = ReturnType<typeof useSearchDocumentsLazyQuery>;
export type SearchDocumentsSuspenseQueryHookResult = ReturnType<typeof useSearchDocumentsSuspenseQuery>;
export type SearchDocumentsQueryResult = Apollo.QueryResult<SearchDocumentsQuery, SearchDocumentsQueryVariables>;
export const LoadSignUrlDocument = gql`
    query LoadSignURL($input: LoadSignURLInput!) {
  loadSignURL(input: $input) {
    signURL
  }
}
    `;

/**
 * __useLoadSignUrlQuery__
 *
 * To run a query within a React component, call `useLoadSignUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSignUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSignUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadSignUrlQuery(baseOptions: Apollo.QueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables> & ({ variables: LoadSignUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
      }
export function useLoadSignUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
        }
export function useLoadSignUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
        }
export type LoadSignUrlQueryHookResult = ReturnType<typeof useLoadSignUrlQuery>;
export type LoadSignUrlLazyQueryHookResult = ReturnType<typeof useLoadSignUrlLazyQuery>;
export type LoadSignUrlSuspenseQueryHookResult = ReturnType<typeof useLoadSignUrlSuspenseQuery>;
export type LoadSignUrlQueryResult = Apollo.QueryResult<LoadSignUrlQuery, LoadSignUrlQueryVariables>;
export const AppointmentDocument = gql`
    query Appointment($input: AppointmentInput!) {
  appointment(input: $input) {
    car {
      carId
      licensePlate
      name
      registrationDate
      model
      line
      exterior
      interior
    }
    createdOn
    customer {
      ... on CustomerBusiness {
        firstName
        lastName
        uuid
        userId
        mobileNumber
        userId
        sfCustomerId
        bpid
        accountType
        sfOrgId
        companyName
      }
      ... on CustomerPrivate {
        firstName
        lastName
        uuid
        userId
        mobileNumber
        userId
        sfCustomerId
        accountType
      }
    }
    end
    expert {
      address
      fullName
      id
    }
    id
    start
    status
    type
    title
  }
}
    `;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables> & ({ variables: AppointmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export function useAppointmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentSuspenseQueryHookResult = ReturnType<typeof useAppointmentSuspenseQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<AppointmentQuery, AppointmentQueryVariables>;