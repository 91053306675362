import {
  Document,
  DocumentCategory,
  DocumentStatus,
  DocumentType,
} from '@smart/adb-shared';
import { ColumnDef } from '@tanstack/react-table';
import { DateFormats, getLocaleDate } from '@ui/library/helpers/date-locale';
import { useLanguageContext } from 'contexts/language-context';
import { useTranslation } from 'react-i18next';

export const GetDocumentsTableColumnDef = (): ColumnDef<
  Document,
  string | null
>[] => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();

  const getTranslation = (value: string, type: string | undefined) =>
    type ? t(`customer.documents.table.${value}.${type.toLowerCase()}`) : '-';

  return [
    {
      header: t('customer.documents.headers.name'),
      accessorKey: 'name',
      enableSorting: false,
    },
    {
      header: t('customer.documents.headers.type'),
      accessorKey: 'type',
      enableSorting: false,
      cell: ({ getValue }) => {
        const value = getValue();
        const documentType = Object.keys(DocumentType).find(
          (key: string) => (DocumentType as any)[key] === value
        );
        return getTranslation('type', documentType);
      },
    },
    {
      header: t('customer.documents.headers.category'),
      accessorKey: 'category',
      enableSorting: false,
      cell: ({ getValue }) => {
        const value = getValue();
        const categoryType = Object.keys(DocumentCategory).find(
          (key: string) => (DocumentCategory as any)[key] === value
        );
        return getTranslation('category', categoryType);
      },
    },
    {
      header: t('customer.documents.headers.modified'),
      accessorKey: 'dateModified',
      enableSorting: true,
      cell: ({ getValue }) =>
        getValue()
          ? getLocaleDate(getValue() as string, locale, DateFormats.DATE)
          : '-',
    },
    {
      header: t('customer.documents.headers.created'),
      accessorKey: 'dateCreated',
      enableSorting: true,
      cell: ({ getValue }) =>
        getValue()
          ? getLocaleDate(getValue() as string, locale, DateFormats.DATE)
          : '-',
    },
    {
      header: t('customer.documents.headers.status'),
      accessorKey: 'status',
      enableSorting: false,
      cell: ({ getValue }) => {
        const ELOToDMPStatusMapper: Record<string, DocumentStatus> = {
          COMPLETED: DocumentStatus.SIGNED,
          SENT: DocumentStatus.STARTED,
        };

        let value = getValue();
        if (value && ELOToDMPStatusMapper[value as string]) {
          value = ELOToDMPStatusMapper[value as string];
        }

        const statusType = Object.keys(DocumentStatus).find(
          (key: string) => (DocumentStatus as any)[key] === value
        );
        return getTranslation('status', statusType);
      },
    },
  ];
};

export enum DocumentAction {
  DOWNLOAD = 'download',
  SIGN = 'sign',
  RENAME = 'rename',
  DELETE = 'delete',
}

export enum UploadType {
  Customer,
  Outlet,
}

/**
 * @const FileTypes
 * extension types for Documents
 */
export const FileTypes = ['jpg', 'jpeg', 'pdf'];

export function removeFileExtension(fileName: string, fileExtension?: string) {
  if (!fileName) return fileName;

  if (fileExtension)
    return fileName.replace(new RegExp(`.${fileExtension}$`), '');

  const subs = fileName.split('.');
  const extension = subs[subs.length - 1];

  if (extension && FileTypes.includes(extension.toLowerCase())) {
    subs.pop();
  }

  return subs.join('.');
}

export const ELO_VERSION_LABEL = '1.0';
export const ELO_VERSION_COMMENT = 'Version 1.0';
